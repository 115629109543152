import * as React from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import {PerksModuleStyles} from './PerksModuleStyles';
import {MdOutlineClose as Cross} from 'react-icons/md';
import Perk from './Perk';

const PerksModule = () => {
  return (
    <PerksModuleStyles className="section section__padding">
      <StaticImage
        className="perks__image--bg"
        src="../../../static/IMG_1447.jpg"
        alt="Perks Module"
        layout="constrained"
        placeholder="tracedSVG"
      />
      <div className="perks__image--overlay"></div>
      <div className="container container__tight">
        <Perk
          title="GDG Organizer"
          content="Organizer of GDG Hong Kong. Love to share everything about technologies.
           Regularly give talks about hot topics on Cloud and Machine Learning."
        >
          <StaticImage
            src="../../../static/logos/gdg-logo.svg"
            alt="Perk Image"
            layout="constrained"
            placeholder="tracedSVG"
          />
        </Perk>
        <span className="perks__divider">
          <Cross />
        </span>
        <Perk
          title="Google Cloud Certified"
          content="Certified in 5 professional certifications offered by Google Cloud.
          4+ years of experience working with cloud technologies."
        >
          <StaticImage
            src="../../../static/logos/gcp-logo.svg"
            alt="Perk Image"
            layout="constrained"
            placeholder="tracedSVG"
          />
        </Perk>
      </div>
    </PerksModuleStyles>
  );
};

export default PerksModule;
